// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-our-work-tsx": () => import("./../../../src/pages/our-work.tsx" /* webpackChunkName: "component---src-pages-our-work-tsx" */),
  "component---src-pages-site-acknowledgements-tsx": () => import("./../../../src/pages/site/acknowledgements.tsx" /* webpackChunkName: "component---src-pages-site-acknowledgements-tsx" */),
  "component---src-pages-site-privacy-tsx": () => import("./../../../src/pages/site/privacy.tsx" /* webpackChunkName: "component---src-pages-site-privacy-tsx" */),
  "component---src-pages-site-terms-tsx": () => import("./../../../src/pages/site/terms.tsx" /* webpackChunkName: "component---src-pages-site-terms-tsx" */),
  "component---src-pages-solutions-tsx": () => import("./../../../src/pages/solutions.tsx" /* webpackChunkName: "component---src-pages-solutions-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-templates-news-article-tsx": () => import("./../../../src/templates/news-article.tsx" /* webpackChunkName: "component---src-templates-news-article-tsx" */),
  "component---src-templates-pillar-tsx": () => import("./../../../src/templates/pillar.tsx" /* webpackChunkName: "component---src-templates-pillar-tsx" */),
  "component---src-templates-solution-tsx": () => import("./../../../src/templates/solution.tsx" /* webpackChunkName: "component---src-templates-solution-tsx" */),
  "component---src-templates-team-member-tsx": () => import("./../../../src/templates/team-member.tsx" /* webpackChunkName: "component---src-templates-team-member-tsx" */)
}

